
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        






























































































































































































.referral-marketing {
  #what-youll-earn .app-card__body {
    @apply tw-bg-white tw-rounded;
  }

  #faq .el-collapse-item {
    margin-bottom: theme('spacing.space-2');
  }

  .app-checkbox {
    .el-checkbox__inner {
      @apply tw-border-0.5 tw-border-jb-grey-400 tw-w-4 tw-h-4;
    }

    .el-checkbox__input.is-checked > span {
      @apply tw-bg-jb-indigo;
    }

    .el-checkbox__input {
      @apply tw-mt-0.5;
    }

    .el-checkbox__label {
      @apply tw-text-jb-indigo tw--ml-0.5;
    }

    .el-checkbox__input.is-checked + .el-checkbox__label {
      @apply tw-text-jb-indigo;
    }
  }

  .referral-marketing-dialog {
    .el-dialog {
      @apply tw-rounded;

      width: calc(100% - 16px);
      max-width: 400px;
    }

    .el-dialog__header {
      @apply tw-pb-0 tw-pt-space-4 tw-px-space-4;
    }

    .el-dialog__body {
      @apply tw-p-space-4;
    }

    .el-dialog__footer {
      @apply tw-p-space-4;
    }
  }
}
